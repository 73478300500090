import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { GoogleRecaptchaSiteKey } from '../const'
import { useLoginMutation } from '../generated/urql.anonymous'
import { useRecaptcha } from '../lib/recaptcha'
import { AuthContext } from '../provider/auth'
import { BasketContext } from '../provider/basket'
import { useI18n } from '../provider/i18n'
import IconInput from '../components/IconInput'
import PersonIcon from '../assets/person.svg'
import LockIcon from '../assets/lock.svg'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Blue300 } from '../lib/colors'
import { LuTextCursorInput } from 'react-icons/lu'
import { TbRefresh } from 'react-icons/tb'
import { WelcomeFrame } from '../components/WelcomeFrame'
import PatientLogo from '../assets/patient-logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { MessageBox } from '../components/MessageBox'
import { ConfirmDialog, ConfirmDialogType } from '../components/ConfirmDialog'
import { useClient } from 'urql'
import {
  GetMainProfileDocument,
  GetMainProfileQuery,
  GetMainProfileQueryVariables,
} from '../generated/urql.client'

export const LoginPage: React.FC = () => {
  const location = useLocation()
  const { setAccessToken, isLogged } = useContext(AuthContext)
  const getRecaptchaToken = useRecaptcha()
  const basket = useContext(BasketContext)
  const i18n = useI18n()
  const navigate = useNavigate()
  const [{ fetching }, login] = useLoginMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [smsCodeRequired, setSMSCodeRequired] = useState(
    location.state?.showSMSCode || false
  )
  const client = useClient()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: (location.state?.email as string) || '',
      password: '',
      smsCode: undefined,
    },
  })
  const [revealPassword, setRevealPassword] = useState(false)
  const confirmDialogRef = useRef<ConfirmDialogType>(null)

  useEffect(() => {
    if (isLogged && location.state?.backTo) {
      navigate(location.state.backTo)
    }
  }, [isLogged, location])

  useEffect(() => {
    setValue('email', location.state?.email || '')
  }, [setValue, location.state?.email])

  useEffect(() => {
    setSMSCodeRequired(location.state?.showSMSCode || false)
  }, [setValue, location.state?.showSMSCode])

  const doLogin = useCallback(
    async function (args: {
      email: string
      password: string
      smsCode?: string
    }) {
      setGeneralError(undefined)

      const recaptchaToken = await getRecaptchaToken({ action: 'login' })

      if (!recaptchaToken) {
        setGeneralError(i18n.t('screens.login.loginError.recaptcha-error'))
        return
      }

      const { data, error } = await login({ ...args, recaptchaToken })

      if (error) {
        switch (error.graphQLErrors[0]?.extensions.code) {
          case 'invalid-credentials':
            setGeneralError(
              i18n.t('screens.login.loginError.invalid-credentials')
            )
            return
          case 'unconfirmed-email':
            navigate(`/register/finish`, {
              state: {
                email: args.email,
                backTo: '/login',
              },
            })
            return
          case 'locked':
            navigate(`/login/recover`, {
              state: {
                email: args.email,
                backTo: location.state?.backTo || '',
              },
            })
            return
          case 'missing-sms-code':
            setSMSCodeRequired(true)
            return
          case 'invalid-sms-code':
            setGeneralError(i18n.t('screens.login.loginError.invalid-sms-code'))
            return
          case 'no-main-profile':
            setGeneralError(i18n.t('screens.login.loginError.no-main-profile'))
            return
          case 'invalid-recaptcha':
            setGeneralError(
              i18n.t('screens.login.loginError.invalid-recaptcha')
            )
            return
          default:
            setGeneralError(error.message)
        }
        return
      }

      if (data?.client_login) {
        await setAccessToken(data.client_login.accessToken)

        const { data: mainProfileData } = await client
          .query<GetMainProfileQuery, GetMainProfileQueryVariables>(
            GetMainProfileDocument,
            {}
          )
          .toPromise()

        const mainProfile = mainProfileData?.client_profile[0]

        if (mainProfile) {
          basket.setProfile(mainProfile)
        }

        if (location.state?.backTo) {
          navigate(location.state.backTo)
        } else {
          navigate('/')
        }
      }
    },
    [
      basket,
      getRecaptchaToken,
      i18n,
      login,
      navigate,
      location.state?.backTo,
      setAccessToken,
    ]
  )

  const resendSMSCode = useCallback(async () => {
    confirmDialogRef.current?.show({
      title: i18n.t('screens.login.resendSMSCode.sent.title'),
      message: i18n.t('screens.login.resendSMSCode.confirm.title'),
      onSuccess: async () => {
        await doLogin({
          email: watch('email'),
          password: watch('password'),
        })
        window.alert(i18n.t('screens.login.resendSMSCode.sent.message'))
      },
    })
  }, [watch, i18n, doLogin])

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${GoogleRecaptchaSiteKey}`}
        ></script>
      </Helmet>
      <WelcomeFrame className="flex flex-col items-center justify-around pt-4">
        <div className="flex-1 flex justify-center">
          <img src={PatientLogo} alt="HomeLab Patient app logo" />
        </div>
        <div className="flex-1 my-0 mx-auto w-[320px] bg-blue-100 bg-opacity-50">
          {!!generalError && <MessageBox>{generalError}</MessageBox>}
          {smsCodeRequired && (
            <MessageBox variant="warning">
              {i18n.t('screens.login.smsCodeRequiredBox')}
            </MessageBox>
          )}
          <form onSubmit={handleSubmit(doLogin)}>
            <div className="mx-4">
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={PersonIcon}
                      width={24}
                      height={24}
                      alt="Person icon"
                    />
                  }
                  {...register('email', {
                    required: i18n.t('screens.login.form.login.rules.required'),
                  })}
                  placeholder={i18n.t('screens.login.form.login.placeholder')}
                />
                {errors.email && (
                  <p className="hl-input-error">{errors.email.message}</p>
                )}
              </div>
              <div className="my-4">
                <IconInput
                  leftIcon={
                    <img
                      src={LockIcon}
                      width={24}
                      height={24}
                      alt="Lock icon"
                    />
                  }
                  rightIcon={
                    revealPassword ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={() => setRevealPassword(!revealPassword)}
                      />
                    )
                  }
                  {...register('password', {
                    required: i18n.t(
                      'screens.login.form.password.rules.required'
                    ),
                  })}
                  type={revealPassword ? 'text' : 'password'}
                  placeholder={i18n.t(
                    'screens.login.form.password.placeholder'
                  )}
                />
                {errors.password && (
                  <p className="hl-input-error">{errors.password.message}</p>
                )}
              </div>
              {smsCodeRequired && (
                <div className="my-4">
                  <IconInput
                    leftIcon={<LuTextCursorInput size={24} color={Blue300} />}
                    rightIcon={
                      <TbRefresh
                        className="cursor-pointer"
                        size={24}
                        color={Blue300}
                        onClick={resendSMSCode}
                      />
                    }
                    {...register('smsCode', {
                      required: i18n.t(
                        'screens.login.form.smsCode.rules.required'
                      ),
                    })}
                    placeholder={i18n.t(
                      'screens.login.form.smsCode.placeholder'
                    )}
                  />
                  {errors.smsCode && (
                    <p className="hl-input-error">{errors.smsCode.message}</p>
                  )}
                </div>
              )}
            </div>
            <div className="my-4">
              <button
                className="hl-button-lg"
                type="submit"
                disabled={fetching}
              >
                {i18n.t('screens.login.form.submit')}
              </button>
            </div>
          </form>
          <p className="m-4 text-center">
            <Link to="/resetpassword">
              {i18n.t('screens.login.links.forgotPassword.text')}
            </Link>{' '}
            |{' '}
            <Link to="/register">
              {i18n.t('screens.login.links.register.register')}
            </Link>
          </p>
        </div>
        <div className="flex-1" />
      </WelcomeFrame>
      <ConfirmDialog ref={confirmDialogRef} />
    </>
  )
}
