import {
  Laboratory_Test_Aggregate_Fields,
  Test,
} from '../generated/urql.client'

export function laboratoryTestsToPriceString(
  laboratoryTests: Array<{ price: number }>
) {
  if (laboratoryTests.length === 0) {
    return '-'
  }

  const prices = laboratoryTests.map((t) => t.price)
  const min = Math.min(...prices)
  const max = Math.max(...prices)

  if (min === max) {
    return `${min}`
  }

  return `${min} - ${max}`
}

export function laboratoryTestsToWaitingTimesList(
  laboratoryTests: Array<{ waitingTime?: string | null }>
) {
  return laboratoryTests
    .filter(
      (laboratoryTest): laboratoryTest is { waitingTime: string } =>
        !!laboratoryTest.waitingTime
    )
    .reduce<string[]>(
      (waitingTimes, { waitingTime }) => [
        ...waitingTimes,
        ...(waitingTimes.includes(waitingTime) ? [] : [waitingTime]),
      ],
      []
    )
}

export function avgTestPrice({
  min,
  max,
}: {
  min?: { price?: number | null } | null
  max?: { price?: number | null } | null
}) {
  if (min?.price === undefined || min.price === null) {
    if (max?.price === undefined || max.price === null) {
      return
    } else {
      return max.price
    }
  }

  if (max?.price === undefined || max.price === null) {
    return min.price
  }

  return (min.price + max.price) / 2
}
