import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import { useClient } from 'urql'
import {
  GetAvailableTestDocument,
  GetAvailableTestQuery,
  useGetTagSubscription,
} from '../../generated/urql.anonymous'
import {
  GetProfilesSubscription,
  GetTestFavouriteDocument,
  GetTestFavouriteQuery,
  GetTestFavouriteQueryVariables,
  useDeleteClientFavouriteTestMutation,
  useGetProfilesSubscription,
  useInsertClientFavouriteTestMutation,
} from '../../generated/urql.client'
import {
  laboratoryTestsToPriceString,
  laboratoryTestsToWaitingTimesList,
} from '../../lib/test'
import { AuthContext } from '../../provider/auth'
import { BasketContext, isTestBasketItem } from '../../provider/basket'
import { useI18n } from '../../provider/i18n'
import { Link, useLocation } from 'react-router-dom'
import { Disclosure, Transition } from '@headlessui/react'
import {
  MdAlarmOn,
  MdKeyboardArrowRight,
  MdShoppingBasket,
  MdStars,
} from 'react-icons/md'
import { Blue600 } from '../../lib/colors'
import Markdown from 'react-markdown'
import ProfileSelect from '../../components/ProfileSelect'
import { MessageBox } from '../../components/MessageBox'
import {
  ConfirmDialog,
  ConfirmDialogType,
} from '../../components/ConfirmDialog'

export const CatalogTestPage = () => {
  const params = useParams<{ id: string }>()
  const auth = useContext(AuthContext)
  const i18n = useI18n()
  const basket = useContext(BasketContext)
  const client = useClient()
  const [generalError, setGeneralError] = useState<string>()
  const [availableTest, setAvailableTest] = useState<GetAvailableTestQuery>()
  const [
    { fetching: insertClientFavouriteTestFetching },
    insertClientFavouriteTest,
  ] = useInsertClientFavouriteTestMutation()
  const [, deleteClientFavouriteTest] = useDeleteClientFavouriteTestMutation()
  const [{ data: profilesData }] = useGetProfilesSubscription()
  const id = parseInt(params.id || '0', 10)
  const [isTestFavourite, setIsTestFavourite] = useState<boolean>()
  const location = useLocation()
  const confirmDialogRef = useRef<ConfirmDialogType>(null)

  const test = availableTest?.test_by_pk
  const profiles = profilesData?.client_profile
  const [selectedProfile, setSelectedProfile] =
    useState<GetProfilesSubscription['client_profile'][number]>()
  const isAvailable = availableTest?.test_by_pk?.isAvailable
  const isAddedToBasket = useMemo(
    () =>
      auth.isLogged
        ? basket.items
            .filter(isTestBasketItem)
            .filter(({ test: { id } }) => test?.id === id).length >=
          (profilesData?.client_profile.length || 0)
        : !!basket.items.find(
            (item) => isTestBasketItem(item) && item.test?.id === id
          ),
    [auth.isLogged, basket.items, profilesData?.client_profile.length, test?.id]
  )

  useEffect(() => {
    !selectedProfile &&
      profiles &&
      setSelectedProfile(profiles.find(({ isMain }) => isMain) || profiles[0])
  }, [profiles])

  const [{ data: tagData }] = useGetTagSubscription({
    pause: !location.state?.tagId,
    variables: {
      id: location.state?.tagId || '',
    },
  })
  const tag = tagData?.tag_by_pk

  const fetchAvailableTest = useCallback(() => {
    if (!basket.address?.location) {
      return
    }

    client
      .query(
        GetAvailableTestDocument,
        {
          id,
          point: basket.address.location,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then(({ data }) => setAvailableTest(data))
      .catch(console.error)
  }, [basket.address?.location, client, id])

  useEffect(fetchAvailableTest, [fetchAvailableTest])

  useEffect(() => {
    if (!auth.accessToken) {
      return
    }

    let cancelled = false

    client
      .query<GetTestFavouriteQuery, GetTestFavouriteQueryVariables>(
        GetTestFavouriteDocument,
        { id },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then(({ data }) => {
        if (!cancelled) {
          setIsTestFavourite(
            !!data?.client_favourite_test_aggregate.aggregate?.count
          )
        }
      })
      .catch((error) => setGeneralError(error.message))

    return () => {
      cancelled = true
    }
  }, [auth.accessToken, client, id, availableTest])

  const doAddToBasket = useCallback(() => {
    if (isAddedToBasket || !test) {
      return
    }

    const profilesCount = profilesData?.client_profile.length || 0

    if (profilesCount === 0) {
      basket.addItem({ test })
    } else if (profilesCount === 1) {
      basket.addItem({ test, profile: profilesData?.client_profile[0] })
    } else {
      basket.addItem({ test, profile: selectedProfile })
    }
  }, [
    basket,
    isAddedToBasket,
    profilesData?.client_profile,
    selectedProfile,
    test,
  ])

  const doRemoveFromBasket = useCallback(() => {
    if (isAddedToBasket && test) {
      const items = basket.items
        .filter(isTestBasketItem)
        .filter((item) => item.test.id === test.id)
      if (items.length === 1) {
        basket.removeItem(items[0])
      } else if (items.length > 1) {
        confirmDialogRef.current?.show({
          message: i18n.t(
            'screens.catalog.test.basket.removeForAllProfiles.title'
          ),
          onSuccess: () => {
            basket.removeItem(...items)
          },
        })
      }
    }
  }, [basket, i18n, isAddedToBasket, test])

  const doAddToFavourites = useCallback(async () => {
    setGeneralError(undefined)
    const { error } = await insertClientFavouriteTest({
      testId: id,
      clientId: auth.clientId,
    })
    if (error) {
      setGeneralError(error.message)
    }
    fetchAvailableTest()
  }, [auth.clientId, fetchAvailableTest, id, insertClientFavouriteTest])

  const doRemoveFromFavourites = useCallback(async () => {
    setGeneralError(undefined)
    const { error } = await deleteClientFavouriteTest({
      testId: id,
      clientId: auth.clientId,
    })
    if (error) {
      setGeneralError(error.message)
    }
    fetchAvailableTest()
  }, [auth.clientId, deleteClientFavouriteTest, fetchAvailableTest, id])

  return (
    <div className="mb-10">
      <div className="text-blue-700 mb-5">
        <Link to="/">{i18n.t('common.breadcrumbs.mainPage')}</Link>
        {' > '}
        {tag && (
          <>
            <Link to={`/tag/${encodeURIComponent(tag.id)}`}>{tag.tag}</Link>
            {' > '}
          </>
        )}
        {test?.name || test?.shortName || '-'}
      </div>

      {generalError && <MessageBox>{generalError}</MessageBox>}

      {test && (
        <>
          <div className="flex justify-between items-center mb-8 mt-5">
            <h1 className="text-2xl md:text-3xl font-bold leading-tight">
              {test.name || test.shortName}
            </h1>
            {auth.accessToken && (
              <button
                className="flex flex-row-reverse md:flex-row items-center gap-2.5"
                onClick={
                  !isTestFavourite ? doAddToFavourites : doRemoveFromFavourites
                }
                disabled={!isAvailable || insertClientFavouriteTestFetching}
              >
                <MdStars size={24} color={Blue600} />
                <span className="text-gray-400 md:underline text-right text-xs md:text-center md:text-base">
                  {!isTestFavourite
                    ? i18n.t('screens.catalog.test.favourites.add')
                    : i18n.t('screens.catalog.test.favourites.remove')}
                </span>
              </button>
            )}
          </div>
          <div className="hl-panel">
            {!!test.description && (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="p-4 md:p-6 text-lg font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                      <>
                        {i18n.t('screens.catalog.test.description.label')}
                        <MdKeyboardArrowRight
                          className={open ? 'rotate-90 transform' : ''}
                          size={24}
                          color={Blue600}
                        />
                      </>
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform max-h-0"
                      enterTo="transform max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform max-h-[1000px]"
                      leaveTo="transform max-h-0"
                    >
                      <Disclosure.Panel className="p-4 md:p-6 border-b border-b-border border-opacity-20">
                        <Markdown className="markdown">
                          {test.description}
                        </Markdown>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            {!!test.preparation && (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="p-4 md:p-6 text-lg font-bold flex justify-between w-full border-b border-b-border border-opacity-20">
                      <>
                        {i18n.t('screens.catalog.test.preparation.label')}
                        <MdKeyboardArrowRight
                          className={open ? 'rotate-90 transform' : ''}
                          size={24}
                          color={Blue600}
                        />
                      </>
                    </Disclosure.Button>
                    <Transition
                      className="overflow-hidden"
                      enter="transition-all ease-in-out duration-[500ms]"
                      enterFrom="transform  max-h-0"
                      enterTo="transform  max-h-[1000px]"
                      leave="transition-all ease-in-out duration-[500ms]"
                      leaveFrom="transform  max-h-[1000px]"
                      leaveTo="transform  max-h-0"
                    >
                      <Disclosure.Panel className="p-4 md:p-6 border-b border-b-border border-opacity-20">
                        <Markdown>{test.preparation}</Markdown>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}
            <div className="bg-blue-200 rounded-b-2xl md:flex justify-between items-center p-5 md:py-12 md:px-8 gap-2.5 md:gap-5 grid grid-cols-2">
              {!!test.availableLaboratoryTests && (
                <>
                  <div className="flex gap-2.5 items-center md:mr-auto justify-center md:justify-start">
                    <MdAlarmOn size={24} color={Blue600} />
                    <p>
                      {i18n.t('screens.catalog.test.waitingTime.label')}:{' '}
                      <strong>
                        {laboratoryTestsToWaitingTimesList(
                          test.availableLaboratoryTests
                        ).join(', ')}
                      </strong>
                    </p>
                  </div>
                  <div className="flex gap-2.5 items-center justify-center md:justify-start">
                    <MdShoppingBasket size={24} color={Blue600} />
                    <p className="text-md md:text-lg font-bold">
                      {i18n.t('screens.catalog.test.price', {
                        price: laboratoryTestsToPriceString(
                          test.availableLaboratoryTests
                        ),
                      })}
                    </p>
                  </div>
                </>
              )}
              <button
                className={`hl-button ${
                  isAddedToBasket ? 'hl-gradient-red' : ''
                }`}
                onClick={!isAddedToBasket ? doAddToBasket : doRemoveFromBasket}
                disabled={!isAvailable}
              >
                {!isAddedToBasket
                  ? i18n.t('screens.catalog.test.basket.add')
                  : i18n.t('screens.catalog.test.basket.remove')}
              </button>
              {auth.accessToken && !isAddedToBasket && (
                <ProfileSelect
                  onChange={setSelectedProfile}
                  value={selectedProfile}
                  filter={(profile) =>
                    !basket.items.find(
                      (item) =>
                        isTestBasketItem(item) &&
                        item.test.id === test.id &&
                        item.profile?.id === profile.id
                    )
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
      <ConfirmDialog ref={confirmDialogRef} />
    </div>
  )
}
